body {
  text-align: center;
  /* background-color: #31304c;  */
  color: white; 
  background-image: url('./josh-purple.png');
  background-size: 20%; 
  background-repeat: repeat; 
}

.leaderboardsWrapper {
  display:block;
}

.leaderboardBoxWrapper {
  display: inline; 
}

.joshOtwWrapper {
  border-radius: 50%; 
  background-color: #31304c;
}

.statWrapper {
  display:inline-block; 
  margin:5%;
  font-weight: bold; 
  font-size: 30px; 


  /* for later fuckery */ 
  /* background-image: url('./josh.png'); 
  background-size: 10px;
  background-repeat: repeat;  */
}

.centerColumn {
  display: inline-block; 
  margin: 5%;
}


@media (max-width: 440px) {
  .leaderboardBoxWrapper {
    display: block; 
  }
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
